<template>
    <img
        @click="handleClick"
        style="cursor: pointer"
        :class="{
            'status-on': val,
            'status-off': !val
        }"
        :src="knoIcon.url"
        alt="Object-Icon"
        class="status-icon"
    />
</template>
<script>
export default {
    props: ['value', 'id', 'knoIcon'],
    data() {
        return {
            val: this.value
        };
    },
    watch: {
        value(val) {
            this.val = val;
        }
    },
    methods: {
        handleClick() {
            //this.val = !this.val;
            this.$emit('execute', !this.val);
        }
    }
};
</script>
<style>
.status-on {
    filter: invert(22%) sepia(27%) saturate(1099%) hue-rotate(25deg)
        brightness(94%) contrast(91%);
}
.status.off {
    filter: none;
}
.status-icon {
    position: absolute;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 60%;
    max-height: 60%;
    width: auto;
    height: 100%;
}
</style>
