<template>
    <v-dialog v-model="dialog" scrollable width="500">
        <v-card>
            <v-card-title class="headline">Browser</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-treeview
                            :open="open"
                            :items="items"
                            disable-per-node
                            item-disabled="locked"
                            activatable
                            hoverable
                            item-key="id"
                            item-text="name"
                            v-on:update:active="select"
                        >
                            <template v-slot:prepend="{ item }">
                                <v-img :src="item.iconUrl" width="30px"></v-img>
                            </template>
                            <template v-slot:append="{ item, open }">
                                <i
                                    v-if="
                                        open &&
                                            item.object.type ==
                                                $CONST.OBJECTTYPE.CATEGORY
                                    "
                                    class="fas fa-lg fa-door-open"
                                ></i>
                                <i
                                    v-if="
                                        !open &&
                                            item.object.type ==
                                                $CONST.OBJECTTYPE.CATEGORY
                                    "
                                    class="fas fa-lg fa-door-closed"
                                ></i>
                            </template>
                        </v-treeview>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-alert
                v-if="error"
                class="mb-0 ml-5 mr-5 mt-2"
                dense
                outlined
                type="error"
            >
                Unzulässiges Objekt ausgewählt!
            </v-alert>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="close">
                    Abbrechen
                </v-btn>
                <v-btn color="green darken-1" text @click="done">
                    Okay
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ipsMixin from '@/mixins/ipsMixin';
export default {
    mixins: [ipsMixin],
    props: {
        types: {
            type: Array,
            default: function() {
                return [
                    this.$CONST.OBJECTTYPE.CATEGORY,
                    this.$CONST.OBJECTTYPE.INSTANCE,
                    this.$CONST.OBJECTTYPE.VARIABLE,
                    this.$CONST.OBJECTTYPE.SCRIPT,
                    this.$CONST.OBJECTTYPE.EVENT,
                    this.$CONST.OBJECTTYPE.MEDIA,
                    this.$CONST.OBJECTTYPE.LINK
                ];
            }
        },
        value: {
            type: Boolean,
            default: false
        },
        // if rawlinks is true, it wil return the id of the link instead of the id of its target object
        rawlinks: {
            type: Boolean,
            default: false
        },
        root: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            rootID: this.root,
            open: ['ID' + this.root],
            selected: null,
            error: false,
            dialog: this.value,
            items: []
        };
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            if (!this.dialog && this.value) {
                this.$emit('input', false);
            }
        }
    },
    computed: {
        objects() {
            return this.$store.state.snapshot.objects;
        }
        /*
        items() {
            return [
                {
                    id: 'ID' + this.rootID,
                    name: this.objects['ID' + this.rootID].name,
                    object: this.objects['ID' + this.rootID],
                    children: this.getChildren(this.rootID)
                }
            ];
        }*/
    },
    methods: {
        select(data) {
            this.selected = data;
        },
        done() {
            const object = this.$store.state.snapshot.objects[this.selected];
            let type = object.type;
            if (type == this.$CONST.OBJECTTYPE.LINK) {
                type = this.$store.state.snapshot.objects[
                    'ID' + object.data.targetID
                ].type;
                if (
                    !this.types.includes(this.$CONST.OBJECTTYPE.LINK) &&
                    !this.rawlinks
                ) {
                    this.selected[0] = 'ID' + object.data.targetID;
                }
            }
            if (this.types.includes(type)) {
                this.$emit('input', false);
                this.$emit('selected', this.selected[0].substring(2));
            } else {
                this.error = true;
                setTimeout(() => {
                    this.error = false;
                }, 3000);
            }
        },
        close() {
            this.$emit('input', false);
        },
        getChildren(id) {
            let children = [];
            if (id != 0) {
                for (const objectID in this.objects) {
                    if (
                        this.objects[objectID].parentID === id &&
                        !this.objects[objectID].hidden
                    ) {
                        children.push({
                            id: objectID,
                            name: this.objects[objectID].name,
                            object: this.objects[objectID],
                            children: this.getChildren(
                                this.objects[objectID].type ===
                                    this.$CONST.OBJECTTYPE.LINK
                                    ? this.objects[objectID].data.targetID
                                    : parseInt(objectID.substring(2))
                            )
                        });
                    }
                }
            }
            return children;
        },
        resolveIcons(array) {
            array.forEach(child => {
                this.$set(
                    child,
                    'iconUrl',
                    this.$api.getIcon(parseInt(child.id.substring(2)))
                );
                if (child.children.length > 0) {
                    this.resolveIcons(child.children);
                }
            });
        },
        addLockedState(array) {
            array.forEach(child => {
                let locked = !this.types.includes(child.object.type);
                if (child.object.type == 6) {
                    locked = false;
                    if (
                        child.object.data &&
                        child.object.data.target &&
                        child.object.data.target.type
                    ) {
                        locked = !this.types.includes(
                            child.object.data.target.type
                        );
                    }
                }
                this.$set(child, 'locked', locked);
                if (child.children.length > 0) {
                    this.addLockedState(child.children);
                }
            });
        }
    },
    beforeMount() {
        this.open.push(this.objects['ID' + this.rootID].name);
        let items = [];
        items.push({
            id: 'ID' + this.rootID,
            name: this.objects['ID' + this.rootID].name,
            object: this.objects['ID' + this.rootID],
            children: this.getChildren(this.rootID)
        });
        this.items = items;

        this.resolveIcons(this.items);
        this.addLockedState(this.items);
        setTimeout(() => {
            this.addLockedState(this.items);
        }, 2000);
        setTimeout(() => {
            this.$forceUpdate();
        }, 4000);
    }
};
</script>
