<template>
    <div id="controller">
        <v-card
            flat
            tile
            :height="tileSize * controlTile.scaleFactorTile + 'vw'"
            :color="
                index % 2 == 0
                    ? 'rgb(255, 255, 255, 0.16)'
                    : 'rgb(255, 255, 255, 0.08)'
            "
            class="d-flex align-center"
        >
            <!-- Clickable icon (f.ex. DominoSwiss Switches ) -->
            <v-card
                v-if="isVisible(SECTION.prependVar)"
                flat
                tile
                class="d-flex align-center justify-center"
                :min-height="getScale(SECTION.prependVar, SCALE.height)"
                :min-width="getScale(SECTION.prependVar, SCALE.width)"
                style="background-color:rgba(180, 225, 10, 0.10)"
            >
                <v-responsive
                    :aspect-ratio="getScale(SECTION.prependVar, SCALE.ratio)"
                    class="d-flex justify-center align-center"
                >
                    <v-card-text class="ma-0 pa-0 text-center">
                        <IpsProfile
                            :variable="controlTile.prependVar"
                            :as-icon="true"
                            @execute="execute($event)"
                        ></IpsProfile>
                    </v-card-text>
                </v-responsive>
            </v-card>
            <!-- ELSE Non-Clickable icon -->
            <v-card
                v-else-if="isVisible(SECTION.prepend)"
                flat
                tile
                class="d-flex align-center justify-center"
                :min-height="getScale(SECTION.prepend, SCALE.height)"
                :min-width="getScale(SECTION.prepend, SCALE.width)"
                style="background-color:rgba(180, 180, 180, 0.10)"
            >
                <v-responsive
                    :aspect-ratio="getScale(SECTION.prepend, SCALE.ratio)"
                    class="d-flex justify-center align-center"
                >
                    <v-card-text class="ma-0 pa-0 text-center">
                        <img
                            :src="iconURL"
                            alt="Object-Icon"
                            class="main-icon"
                        />
                    </v-card-text>
                </v-responsive>
            </v-card>
            <!-- Control Instance Name -->
            <v-card
                v-if="isVisible(SECTION.name)"
                flat
                tile
                class="d-flex align-center justify-center"
                :height="getScale(SECTION.name, SCALE.height)"
                :width="getScale(SECTION.name, SCALE.width)"
                style="background-color:rgba(0,0,0,0)"
            >
                <v-responsive
                    :aspect-ratio="getScale(SECTION.name, SCALE.ratio)"
                    class="d-flex justify-center align-center"
                >
                    <v-card-text
                        class="ma-0 text-center"
                        :style="{ lineHeight: fontSize + 'vw' }"
                        style="padding: 0 1vw"
                    >
                        <span :style="{ fontSize: fontSize + 'vw' }">
                            {{ controlTile.name }}
                        </span>
                        <span
                            v-if="isOffline"
                            class="ma-2 red--text"
                            :style="{ fontSize: fontSize + 'vw' }"
                        >
                            Offline
                        </span>
                    </v-card-text>
                </v-responsive>
            </v-card>
            <!-- Control variable(s) of the tile -->
            <v-card
                v-if="isVisible(SECTION.controls)"
                flat
                tile
                style="background-color:rgba(0,0,0,0)"
                class="flex-grow-1 flex-shrink-0 ml-2"
            >
                <v-card-text class="ma-0 pa-0 text-center">
                    <v-card
                        v-for="variable in controlTile.controlVars"
                        :key="instance.id + '_' + variable.id"
                        flat
                        style="background-color: rgb(0,0,0,0)"
                        class="px-0"
                        :height="
                            tileSize * controlTile.scaleFactorControl + 'vw'
                        "
                    >
                        <v-container fill-height fluid class="pa-0">
                            <v-row align="center" justify="center" class="pa-0">
                                <v-col class="pa-0">
                                    <IpsProfile
                                        v-if="variable"
                                        :variable="variable"
                                        @execute="execute($event)"
                                    ></IpsProfile>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-card-text>
            </v-card>
            <!-- Modal trigger for ControlModal (modal with all instance variables) -->
            <v-card
                v-if="isVisible(SECTION.append)"
                flat
                tile
                class="d-flex align-center justify-center"
                :min-height="getScale(SECTION.append, SCALE.height)"
                :min-width="getScale(SECTION.append, SCALE.width)"
                style="background-color:rgba(0,0,0,0)"
            >
                <v-responsive
                    :aspect-ratio="getScale(SECTION.append, SCALE.ratio)"
                    class="d-flex justify-center align-center"
                >
                    <v-card-text class="ma-0 pa-0 text-center">
                        <v-icon
                            style="cursor:pointer"
                            @click="showControlsModal = true"
                        >
                            fas fa-ellipsis-v
                        </v-icon>
                    </v-card-text>
                </v-responsive>
            </v-card>
        </v-card>

        <v-dialog v-model="showControlsModal" scrollable max-width="500">
            <ControlModal
                :font-size="fontSize"
                :variables="controlTile.detailVars"
                :instance="instance"
                @close="showControlsModal = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import IpsProfile from '@/components/profiles/IPSProfile';
import helpers from '@/mixins/helpers';
import ControlModal from './ControlModal.vue';
import {
    isKnockautXInstance,
    CONTROL_TILE_SECTIONS,
    CONTROL_TILE_SCALES
} from '@/util/symcon/moduleUtil';

import { getControlTile } from '@/util/configUtil';

export default {
    props: ['id', 'instance', 'configuration', 'index'],
    components: {
        IpsProfile,
        ControlModal
    },
    mixins: [helpers],
    data() {
        return {
            controlTile: null,
            executed: false,
            showControlsModal: false,
            isKnockautXInstance: false,
            isOffline: false,
            SECTION: CONTROL_TILE_SECTIONS,
            SCALE: CONTROL_TILE_SCALES
        };
    },
    computed: {
        iconURL() {
            if (this.controlTile && this.controlTile.icon) {
                return this.$api.getIcon(this.controlTile.icon);
            } else {
                return this.$api.getIcon(this.instance);
            }
        }
    },
    beforeMount() {
        this.isKnockautXInstance = isKnockautXInstance(this.instance);
        this.controlTile = this.getTileConfig();
        if (
            this.isKnockautXInstance &&
            this.configuration &&
            this.configuration.config
        ) {
            this.isOffline = !this.configuration.config.online;
        }
    },
    methods: {
        isVisible(section) {
            const ct = this.controlTile;
            switch (section) {
                case this.SECTION.prependVar:
                    return ct.hasVisiblePrepend && ct.prependVar;
                case this.SECTION.prepend:
                    return ct.hasVisiblePrepend;
                case this.SECTION.name:
                    return ct.hasVisibleName;
                case this.SECTION.controls:
                    return ct.hasVisibleControls;
                case this.SECTION.append:
                    return ct.hasVisibleAppend && ct.detailVars.length >= 1;
            }
            return true;
        },
        // returns the absolute size for different section and type combinations.
        getScale(section, scaleType) {
            const ct = this.controlTile;

            // height only depends on number of controlVars.
            if (scaleType === this.SCALE.height) {
                return this.tileSize * ct.scaleFactorTile + 'vw';
            }

            // get respective stretch factor.
            let stretch = 1;
            switch (section) {
                case this.SECTION.prependVar:
                case this.SECTION.prepend:
                    stretch = ct.scalePrepend;
                    break;
                case this.SECTION.name:
                    stretch = ct.scaleName;
                    break;
                case this.SECTION.controls:
                    stretch = ct.scaleControls;
                    break;
                case this.SECTION.append:
                    stretch = ct.scaleAppend;
                    break;
            }

            // "undefined" can reset width and aspect-ratio.
            if (stretch === 'auto') {
                return undefined;
            }

            // calculate and return absolute size
            switch (scaleType) {
                case this.SCALE.ratio:
                    return stretch / ct.scaleFactorTile;
                case this.SCALE.width:
                    return this.tileSize * stretch + 'vw';
            }
            return stretch;
        },
        async execute(action) {
            try {
                await this.$api.execute(action);
            } catch (ex) {
                this.$localNotifier.addNewNotification(ex.message, ex);
            }
        },
        getTileConfig() {
            // get all instance children of type VARIABLE which are hidden=false.
            const variables = this.getChildren(
                this.instance.data.targetID,
                this.$CONST.OBJECTTYPE.VARIABLE
            );

            // convert variables from object to array and add aditional data.
            let sortable = [];
            for (let key in variables) {
                let variable = variables[key];
                // set id
                variable.id = key.substring(2);
                // set profile
                let profileKey = variable.data.customProfile
                    ? variable.data.customProfile
                    : variable.data.profile;
                variable.profile = this.$store.state.snapshot.profiles[
                    profileKey
                ];
                // set icon
                variable.data.knoIcon = {
                    url: this.iconURL,
                    size: this.iconSize
                };
                // add to sortable array
                sortable.push(variable);
            }

            // sort by name (prio 2)
            sortable.sort((a, b) => {
                const a_name = a.name.toLowerCase();
                const b_name = b.name.toLowerCase();
                return a_name > b_name ? 1 : b_name > a_name ? -1 : 0;
            });

            // sort by position (prio 1)
            sortable.sort((a, b) => {
                return a.position - b.position;
            });

            return getControlTile(this.instance, sortable);
        }
    }
};
</script>

<style>
.category-text {
    color: #b5e10a;
}
.scene-text {
    color: #ff8c1a;
}
.card-clickable {
    cursor: pointer;
}
.fixed-height {
    height: 28px;
}
.v-input__slot {
    margin-bottom: 0 !important;
}

.v-window__next {
    background: rgba(0, 0, 0, 0) !important;
    margin: 0 !important;
}
.v-window__next > .v-btn {
    /*width: 16px !important;
    height: 16px !important;
    margin: 12px 0 !important;*/
    position: relative;
}

.v-window__prev {
    background: rgba(0, 0, 0, 0) !important;
    margin: 0 !important;
}
.v-window__prev > .v-btn {
    /*width: 16px !important;
    height: 16px !important;*/
    margin: 2px 0 !important;
    position: relative;
}
.v-window-item--active {
    width: calc(100% - 64px);
    left: 0;
    right: 0;
    margin: auto;
}
.fa-size-force {
    font-size: 1em !important;
}
.v-window__next > button > span.v-btn__content > i {
    font-size: 18px !important;
}
.v-window__prev > button > span.v-btn__content > i {
    font-size: 18px !important;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
    opacity: 0 !important;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
    opacity: 0 !important;
}
span.v-ripple__container {
    display: none;
}
.main-icon {
    position: absolute;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 60%;
    max-height: 60%;
    width: auto;
    height: 100%;
}
</style>
