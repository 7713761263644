<template>
    <div id="basictile">
        <v-card
            v-if="
                tiletype != 'sensors' &&
                    tiletype != 'controls' &&
                    tiletype != 'variables' &&
                    tiletype != 'alerts'
            "
            flat
            tile
            :class="{
                'card-clickable': tile.clickable
            }"
            @click.stop="handleClick"
            class="color-tile"
        >
            <v-responsive
                :aspect-ratio="9 / 9"
                class="d-flex justify-center align-center"
            >
                <v-card-text class="ma-0 pa-0 text-center">
                    <img class="tile-icon" :src="tile.icon" alt="Object-Icon" />
                    <b-icon
                        v-if="tiletype == 'categories'"
                        name="TileType_Category"
                        class="tile-type-icon"
                    ></b-icon>
                    <b-icon
                        v-if="tiletype == 'scenes'"
                        name="TileType_Scene"
                        class="tile-type-icon"
                    ></b-icon>
                    <div
                        class="tile-type-text-container"
                        :style="{ fontSize: fontSize + 'vw' }"
                    >
                        <div class="tile-type-text">
                            {{ name.toUpperCase() }}
                        </div>
                    </div>
                </v-card-text>
            </v-responsive>
        </v-card>
        <SensorTile
            v-else-if="tiletype == 'sensors'"
            :instance="instance"
            :data="{
                name: name.toUpperCase(),
                iconUrl: tile.icon,
                iconSize: iconSize,
                fontSize: fontSize
            }"
        ></SensorTile>
        <variable-tile
            v-else-if="tiletype == 'variables'"
            :instance="instance"
            :fontSize="fontSize"
        ></variable-tile>
        <alert-tile
            v-else-if="tiletype == 'alerts'"
            :instance="instance"
            :fontSize="fontSize"
            :iconSize="iconSize"
            :tile="tile"
        ></alert-tile>
        <v-snackbar v-model="executed" :timeout="2000">
            "{{ name }}"-Szene wurde ausgeführt!
            <v-btn color="primary" text @click="executed = false">
                Okay
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import SensorTile from '@/components/tiles/SensorTile';
import VariableTile from '@/components/tiles/VariableTile';
import AlertTile from '@/components/tiles/AlertTile';
import {
    KNOCKAUT_X_TemperatureHumiditySensor_MODULE_ID,
    KNOCKAUT_X_VibrationSensor_MODULE_ID,
    KNOCKAUT_X_MotionSensor_MODULE_ID,
    KNOCKAUT_X_Co2Sensor_MODULE_ID,
    KNOCKAUT_X_Thermostat_MODULE_ID,
    KNOCKAUT_X_TemperatureHumiditySensorLCD_MODULE_ID,
    KNOCKAUT_X_WallThermostate_MODULE_ID,
    KNOCKAUT_X_SmokeAlarm_MODULE_ID,
    KNOCKAUT_X_KnobDimmerSwitch_MODULE_ID,
    KNOCKAUT_X_WirelessSwitch1Channel_MODULE_ID,
    KNOCKAUT_X_WirelessSwitch4Channel_MODULE_ID,
    KNOCKAUT_X_WirelessSwitchMini1Channel_MODULE_ID,
    KNOCKAUT_X_WaterSensor_MODULE_ID
} from '../../util/symcon/moduleUtil';

const InstanceModuleIdToIconMap = {
    [KNOCKAUT_X_TemperatureHumiditySensor_MODULE_ID]: 'Temperature',
    [KNOCKAUT_X_Thermostat_MODULE_ID]: 'Temperature',
    [KNOCKAUT_X_TemperatureHumiditySensorLCD_MODULE_ID]: 'Temperature',
    [KNOCKAUT_X_WallThermostate_MODULE_ID]: 'Temperature',
    [KNOCKAUT_X_VibrationSensor_MODULE_ID]: 'Fog',
    [KNOCKAUT_X_Co2Sensor_MODULE_ID]: 'Leaf',
    [KNOCKAUT_X_SmokeAlarm_MODULE_ID]: 'Flame',
    [KNOCKAUT_X_MotionSensor_MODULE_ID]: 'Motion',
    [KNOCKAUT_X_WaterSensor_MODULE_ID]: 'Drops',
    [KNOCKAUT_X_KnobDimmerSwitch_MODULE_ID]: 'Light',
    [KNOCKAUT_X_WirelessSwitch1Channel_MODULE_ID]: 'Remote',
    [KNOCKAUT_X_WirelessSwitch4Channel_MODULE_ID]: 'Remote',
    [KNOCKAUT_X_WirelessSwitchMini1Channel_MODULE_ID]: 'Remote'
};

export default {
    components: {
        SensorTile,
        VariableTile,
        AlertTile
    },
    props: ['id', 'instance', 'tiletype'],
    data() {
        return {
            icon: this.instance.icon,
            name: this.instance.name,
            executed: false,
            tile: {
                clickable: true,
                isCategory: false,
                isScene: false,
                icon: ''
            }
        };
    },
    beforeMount() {
        let instanceIcon = null;
        if (this.instance.icon) {
            // Use icon that was manually set to the link
            instanceIcon = this.instance.icon;
        } else if (
            this.instance.data &&
            this.instance.data.target &&
            this.instance.data.target.data &&
            InstanceModuleIdToIconMap[this.instance.data.target.data.moduleID]
        ) {
            // Use default icon of this instance type
            instanceIcon =
                InstanceModuleIdToIconMap[
                    this.instance.data.target.data.moduleID
                ];
        }
        if (instanceIcon) {
            this.tile.icon = this.$api.getIcon(instanceIcon);
        } else {
            // Use other default icon based on type
            this.tile.icon = this.$api.getIcon(this.instance);
            switch (this.tiletype) {
                case 'categories':
                    this.tile.isCategory = true;
                    break;
                case 'controls':
                    break;
                case 'sensors':
                    this.tile.clickable = false;
                    break;
                case 'scenes':
                    this.tile.isScene = true;
                    break;
            }
        }
    },
    computed: {
        fontSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return '2';
                case 'sm':
                    return '1.4';
                case 'md':
                    return '1.2';
                case 'lg':
                    return '1.0';
                case 'xl':
                    return '0.9';
            }
            return '2vx';
        },
        progress() {
            return this.$store.state.loading;
        },
        iconSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return '40vw';
                case 'sm':
                    return '70vw';
                case 'md':
                    return '60vw';
                default:
                    return '60vw';
            }
        }
    },
    methods: {
        handleClick(event) {
            if (this.tile.clickable) {
                switch (this.tiletype) {
                    case 'categories':
                        this.$router.push({
                            name: 'Home',
                            params: {
                                ...this.$route.params,
                                roomId: this.id
                            }
                        });
                        break;
                    case 'controls':
                        this.$store.commit('showInstanceDialog', this.id);
                        break;
                    case 'scenes':
                        this.loading = true;
                        this.$api.runScene(this.id).then(() => {
                            this.executed = true;
                        });
                        break;
                }
            } else {
                event.stopPropagation();
            }
        }
    }
};
</script>

<style scoped>
.category-text {
    color: #b5e10a;
}
.scene-text {
    color: #ff8c1a;
}
.category-tile {
    background-color: rgb(59, 54, 50) !important;
}
.scene-tile {
    background-color: rgb(59, 54, 50) !important;
}
.card-clickable {
    cursor: pointer;
}
.fixed-height {
    height: 28px;
}
</style>
