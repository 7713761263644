<template>
    <div>
        <v-divider v-if="item === 'divider'"></v-divider>
        <v-list-item
            :active="isActive"
            :class="{ active: isActive, 'v-list-item--active': isActive }"
            v-else-if="!('subitems' in item)"
            :to="{
                name: item.routeName,
                params: {
                    ...$route.params,
                    ...item.routeParams
                }
            }"
            :style="{
                'background-color': index == 0 ? '#292929' : '',
                'padding-top': index == 0 ? '10px' /*'28px'*/ : '0',
                'padding-bottom': index == 0 ? '10px' : '0',
                border: index == 0 ? 'none' : ''
            }"
            style="padding-left: 24px"
            :active-class="index != 0 ? 'active' : ''"
            :disabled="index == 0"
        >
            <v-list-item-action class="my-0">
                <i
                    v-if="item.icon.startsWith('fa')"
                    :class="item.icon"
                    style="height:32px; padding-top:5px"
                ></i>
                <img
                    v-else
                    :src="$api.getIcon(item.icon)"
                    style="height: 30px"
                />
            </v-list-item-action>
            <v-list-item-title>
                {{ item.title }}
            </v-list-item-title>
        </v-list-item>
    </div>
</template>
<script>
export default {
    name: 'DrawerListItem',
    props: ['item', 'index'],
    data() {
        return {
            isActive: false
        };
    },
    computed: {
        newNavCategorySignal() {
            return this.$store.state.newNavCategorySignal;
        }
    },
    watch: {
        newNavCategorySignal: {
            handler(update) {
                if (update) {
                    const navItemId = this.item.id;
                    const target = this.$route.params;
                    this.isActive = false;
                    if (navItemId == target.id) {
                        this.isActive = true;
                    }
                    this.$store.commit('resetNavCategorySignal');
                }
            },
            immediate: true
        }
    }
};
</script>

<style scoped>
.v-list-item--active {
    border-left: solid 6px #b5e10a;
    padding-left: 18px !important;
}
</style>
