var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"color-tile",attrs:{"flat":"","tile":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-responsive',{staticClass:"d-flex justify-center align-center",attrs:{"aspect-ratio":9 / 9}},[_c('v-card-text',{staticClass:"ma-0 pa-0 text-center"},[_c('img',{staticClass:"tile-icon",class:{
                        'filter-warning': _vm.warning,
                        'filter-ok': !_vm.warning
                    },attrs:{"src":_vm.$api.getIcon('Warning'),"alt":"Object-Icon"}}),_c('b-icon',{staticClass:"tile-type-icon",attrs:{"name":"TileType_Warning"}}),_c('div',{staticClass:"tile-type-text-container",style:({ fontSize: _vm.fontSize + 'vw' })},[_c('div',{staticClass:"tile-type-text"},[_vm._v(" "+_vm._s(_vm.instance.name.toUpperCase())+" ")])])],1)],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"secondary"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.instance.name.toUpperCase())+" ")]),_c('v-card-text',_vm._l((_vm.variables),function(variable,index){return _c('div',{key:index},[(variable.ident != 'Warnings')?_c('v-row',{class:{ 'mt-3': variable.ident != 'Warning' }},[(variable.ident != 'Warning')?_c('v-col',{staticClass:"py-0",staticStyle:{"margin-top":"0"}},[_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(variable.name)+": "),_c('span',{staticClass:"primary--text",staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.getValue(variable))+" ")])])]):_vm._e(),(variable.ident == 'Warning')?_c('v-col',[_c('span',[_c('v-alert',{class:{
                                        'mb-1': _vm.sammelwarnungen.length == 0
                                    },attrs:{"dense":"","text":"","type":variable.data.value
                                            ? 'warning'
                                            : 'success'}},[_vm._v(" "+_vm._s(_vm.getValue(variable))+" ")])],1)]):_vm._e()],1):_c('div',_vm._l((_vm.sammelwarnungen),function(alarm,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"py-0"},[_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.getParentName(alarm.object))+": "),(alarm.object)?_c('span',{class:{
                                            'primary--text': !alarm.object
                                                .data.value,
                                            'warning--text':
                                                alarm.object.data.value
                                        },staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.getValue(alarm.object))+" ")]):_c('span',[_vm._v(" #"+_vm._s(alarm.id)+" ")])])])],1)}),1)],1)}),0),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" schliessen ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }