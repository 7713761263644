import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';

import Vue from 'vue';

import * as Sentry from '@sentry/browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import constants from './scripts/constants';
import BIcon from './assets/icons/BIcon';

import { Device } from '@capacitor/device';
import { LocalNotifier } from './plugins/localNotifier';

Vue.component('b-icon', BIcon);

import { version as appVersion } from '../package.json';

Vue.config.productionTip = false;
Vue.prototype.$CONST = constants;

const moment = require('moment');
require('moment/locale/de');
Vue.use(require('vue-moment'), {
    moment
});

// Init Sentry error tracking
if (process.env.VUE_APP_SENTRY_KEY) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_KEY,
        integrations: [
            new VueIntegration({
                Vue,
                tracing: true
            }),
            new Integrations.BrowserTracing()
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0
    });
}
Vue.prototype.$sentry = Sentry;

// Init Application Insight
if (process.env.VUE_APP_AZURE_APPLICATION_INSIGHTS) {
    // Fetch device info
    let deviceInfo = null;
    const logDeviceInfo = async () => {
        deviceInfo = await Device.getInfo();
    };
    logDeviceInfo();

    const baseName = 'KNOCKAUT_Ultimate';
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.VUE_APP_AZURE_APPLICATION_INSIGHTS,
            enableAutoRouteTracking: true
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    router.beforeEach((to, from, next) => {
        const name = baseName + ' / ' + to.name;
        appInsights.startTrackPage(name);
        next();
    });
    router.afterEach(route => {
        const name = baseName + ' / ' + route.name;
        const url = location.protocol + '//' + location.host + route.fullPath;
        let properties = {
            app_version: appVersion
        };
        if (deviceInfo) {
            properties.user_id = deviceInfo.uuid;
            properties.platform = deviceInfo.platform;
            properties = {
                ...deviceInfo,
                ...properties
            };
            console.log('full prop', properties);
        }
        appInsights.stopTrackPage(name, url, properties);
        appInsights.flush();
    });
    Vue.prototype.$appInsights = appInsights;
}

// START Knockaut Setup
const host = window.location.host;

const hostinfo = {
    secure: !host.includes(':'),
    host: host,
    protocol: window.location.protocol
};

Vue.prototype.$api = null;

// PHP parser for Symcon Form Component Data (f.ex. onChange and onClick)
Vue.config.productionTip = false;
const engine = require('php-parser');

const parser = new engine({
    parser: {
        extractDoc: true,
        php7: true
    },
    ast: {
        withPositions: false
    }
});

Vue.prototype.$parser = parser;

const ln = Vue.observable(new LocalNotifier());
Vue.prototype.$localNotifier = ln;

// Add filter() function to Object-Prototype
Object.filter = (obj, predicate) =>
    Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});

// eslint-disable-next-line no-unused-vars
const vue = new Vue({
    router,
    store,
    vuetify,
    created() {
        this.$store.commit('setHostInfo', hostinfo);
    },
    icons: {
        iconfont: 'fa'
    },
    render: h => h(App)
}).$mount('#app');
