<template>
    <v-card
        tile
        flat
        color="rgb(0,0,0,0)"
        class="d-flex justify-center transparent"
        style="font-size:10pt"
    >
        <v-card
            class="transparent text-center grey--text text--lighten-1"
            tile
            flat
            :class="{ 'accent--text': edit, 'edit-pointer': edit }"
            @[clickable]="browse = true"
        >
            <p
                class="mb-0"
                :style="{ 'font-size': fontSize + 'pt' }"
                style="line-height: 1"
            >
                <span :style="{ 'font-size': fontSize / 2 + 'pt' }">{{
                    prefix
                }}</span
                >{{ value
                }}<span :style="{ 'font-size': fontSize / 2 + 'pt' }">{{
                    suffix
                }}</span>
            </p>
            <p class="mb-0" :style="{ 'font-size': fontSize / 1.65 + 'pt' }">
                <small>{{ name }}</small>
            </p>
            <button v-if="edit" style="color:red" @click.stop="destruct">
                <i class="fas fa-times-circle fa-lg"></i>
            </button>
        </v-card>
        <SymconObjectBrowser
            v-if="edit"
            v-model="browse"
            :types="[$CONST.OBJECTTYPE.VARIABLE]"
            :value="variableid"
            :root="$store.state.rootID"
            @selected="update"
        ></SymconObjectBrowser>
    </v-card>
</template>
<script>
import SymconObjectBrowser from '@/components/SymconObjectBrowser';
export default {
    props: ['edit', 'variableid', 'index'],
    components: { SymconObjectBrowser },
    data() {
        return {
            value: '',
            prefix: '',
            suffix: '',
            name: '',
            newVariableId: 0,
            browse: false
        };
    },
    computed: {
        varValue() {
            let variable = this.$store.state.snapshot.objects[
                'ID' + this.variableid
            ];
            if (variable.type == this.$CONST.OBJECTTYPE.LINK) {
                variable = this.$store.state.snapshot.objects[
                    'ID' + variable.data.targetID
                ];
            }
            if (variable && variable.data) {
                return variable.data.value;
            } else {
                return undefined;
            }
        },
        clickable() {
            return this.edit ? 'click' : null;
        },
        fontSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return '12';
                case 'sm':
                    return '18';
                case 'md':
                    return '20';
                case 'lg':
                    return '20';
                case 'xl':
                    return '20';
            }
            return '1.3';
        }
    },
    watch: {
        variableid() {
            this.updateVariable();
        },
        varValue(value) {
            this.value = value;
        }
    },
    methods: {
        updateVariable() {
            const snapshot = this.$store.state.snapshot;
            let variable = snapshot.objects['ID' + this.variableid];
            this.name = variable.name;
            if (variable.type == this.$CONST.OBJECTTYPE.LINK) {
                variable = snapshot.objects['ID' + variable.data.targetID];
            }
            if (!variable) {
                this.value = `Variable ${this.variableid} existiert nicht.`;
                return;
            }
            const profile = snapshot.profiles[variable.data.profile];
            this.value = this.varValue;
            if (profile && this.value !== undefined) {
                this.prefix = profile.prefix;
                this.suffix = profile.suffix;
                if (profile.associations.length > 0) {
                    const resolvedValue = profile.associations.find(
                        element => element.value == this.value
                    );
                    if (resolvedValue) {
                        this.value = resolvedValue.name;
                    }
                }
            }
        },
        destruct() {
            this.$emit('delete', this.index);
        },
        update(id) {
            this.$emit('update', this.index, id);
        }
    },
    beforeMount() {
        this.updateVariable();
    }
};
</script>
<style scoped>
.edit-pointer {
    cursor: pointer;
}
</style>
