var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"basictile"}},[(
            _vm.tiletype != 'sensors' &&
                _vm.tiletype != 'controls' &&
                _vm.tiletype != 'variables' &&
                _vm.tiletype != 'alerts'
        )?_c('v-card',{staticClass:"color-tile",class:{
            'card-clickable': _vm.tile.clickable
        },attrs:{"flat":"","tile":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_c('v-responsive',{staticClass:"d-flex justify-center align-center",attrs:{"aspect-ratio":9 / 9}},[_c('v-card-text',{staticClass:"ma-0 pa-0 text-center"},[_c('img',{staticClass:"tile-icon",attrs:{"src":_vm.tile.icon,"alt":"Object-Icon"}}),(_vm.tiletype == 'categories')?_c('b-icon',{staticClass:"tile-type-icon",attrs:{"name":"TileType_Category"}}):_vm._e(),(_vm.tiletype == 'scenes')?_c('b-icon',{staticClass:"tile-type-icon",attrs:{"name":"TileType_Scene"}}):_vm._e(),_c('div',{staticClass:"tile-type-text-container",style:({ fontSize: _vm.fontSize + 'vw' })},[_c('div',{staticClass:"tile-type-text"},[_vm._v(" "+_vm._s(_vm.name.toUpperCase())+" ")])])],1)],1)],1):(_vm.tiletype == 'sensors')?_c('SensorTile',{attrs:{"instance":_vm.instance,"data":{
            name: _vm.name.toUpperCase(),
            iconUrl: _vm.tile.icon,
            iconSize: _vm.iconSize,
            fontSize: _vm.fontSize
        }}}):(_vm.tiletype == 'variables')?_c('variable-tile',{attrs:{"instance":_vm.instance,"fontSize":_vm.fontSize}}):(_vm.tiletype == 'alerts')?_c('alert-tile',{attrs:{"instance":_vm.instance,"fontSize":_vm.fontSize,"iconSize":_vm.iconSize,"tile":_vm.tile}}):_vm._e(),_c('v-snackbar',{attrs:{"timeout":2000},model:{value:(_vm.executed),callback:function ($$v) {_vm.executed=$$v},expression:"executed"}},[_vm._v(" \""+_vm._s(_vm.name)+"\"-Szene wurde ausgeführt! "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.executed = false}}},[_vm._v(" Okay ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }