var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item === 'divider')?_c('v-divider'):(!('subitems' in _vm.item))?_c('v-list-item',{class:{ active: _vm.isActive, 'v-list-item--active': _vm.isActive },staticStyle:{"padding-left":"24px"},style:({
            'background-color': _vm.index == 0 ? '#292929' : '',
            'padding-top': _vm.index == 0 ? '10px' /*'28px'*/ : '0',
            'padding-bottom': _vm.index == 0 ? '10px' : '0',
            border: _vm.index == 0 ? 'none' : ''
        }),attrs:{"active":_vm.isActive,"to":{
            name: _vm.item.routeName,
            params: {
                ..._vm.$route.params,
                ..._vm.item.routeParams
            }
        },"active-class":_vm.index != 0 ? 'active' : '',"disabled":_vm.index == 0}},[_c('v-list-item-action',{staticClass:"my-0"},[(_vm.item.icon.startsWith('fa'))?_c('i',{class:_vm.item.icon,staticStyle:{"height":"32px","padding-top":"5px"}}):_c('img',{staticStyle:{"height":"30px"},attrs:{"src":_vm.$api.getIcon(_vm.item.icon)}})]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.item.title)+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }