<template>
    <div>
        <v-card flat tile class="color-tile" @click="dialog = true">
            <v-responsive
                :aspect-ratio="9 / 9"
                class="d-flex justify-center align-center"
            >
                <v-card-text class="ma-0 pa-0 text-center">
                    <img
                        class="tile-icon"
                        :class="{
                            'filter-warning': warning,
                            'filter-ok': !warning
                        }"
                        :src="$api.getIcon('Warning')"
                        alt="Object-Icon"
                    />
                    <b-icon
                        name="TileType_Warning"
                        class="tile-type-icon"
                    ></b-icon>
                    <div
                        class="tile-type-text-container"
                        :style="{ fontSize: fontSize + 'vw' }"
                    >
                        <div class="tile-type-text">
                            {{ instance.name.toUpperCase() }}
                        </div>
                    </div>
                </v-card-text>
            </v-responsive>
        </v-card>
        <v-dialog v-model="dialog" width="unset">
            <v-card color="secondary">
                <v-card-title class="headline">
                    {{ instance.name.toUpperCase() }}
                </v-card-title>

                <v-card-text>
                    <div v-for="(variable, index) in variables" :key="index">
                        <v-row
                            v-if="variable.ident != 'Warnings'"
                            :class="{ 'mt-3': variable.ident != 'Warning' }"
                        >
                            <v-col
                                v-if="variable.ident != 'Warning'"
                                class="py-0"
                                style="margin-top: 0"
                            >
                                <span style="font-size: 13px">
                                    {{ variable.name }}:
                                    <span
                                        class="primary--text"
                                        style="font-size: 15px"
                                        >{{ getValue(variable) }}
                                    </span>
                                </span>
                            </v-col>
                            <v-col v-if="variable.ident == 'Warning'">
                                <span>
                                    <v-alert
                                        dense
                                        text
                                        :class="{
                                            'mb-1': sammelwarnungen.length == 0
                                        }"
                                        :type="
                                            variable.data.value
                                                ? 'warning'
                                                : 'success'
                                        "
                                    >
                                        {{ getValue(variable) }}
                                        <!-- Warning Alert -->
                                    </v-alert>
                                </span>
                            </v-col>
                        </v-row>
                        <div v-else>
                            <v-row
                                v-for="(alarm, index) in sammelwarnungen"
                                :key="index"
                            >
                                <v-col class="py-0">
                                    <span style="font-size: 13px">
                                        {{ getParentName(alarm.object) }}:
                                        <span
                                            v-if="alarm.object"
                                            :class="{
                                                'primary--text': !alarm.object
                                                    .data.value,
                                                'warning--text':
                                                    alarm.object.data.value
                                            }"
                                            style="font-size: 15px"
                                            >{{ getValue(alarm.object) }}
                                        </span>
                                        <span v-else> #{{ alarm.id }} </span>
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="primary--text" text @click="dialog = false">
                        schliessen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: ['instance', 'fontSize', 'iconSize'],
    data() {
        return {
            guid: null,
            variables: null,
            warning: false,
            dialog: false,
            isSammelwarnung: false,
            sammelwarnungen: []
        };
    },
    watch: {
        variables: {
            deep: true,
            handler() {
                for (const key in this.variables) {
                    this.updateTileColor(this.variables[key]);
                }
            }
        }
    },
    methods: {
        initialize(instance) {
            this.object = instance;
            if (instance.type == 6) {
                this.guid = instance.data.target.data.moduleID;
                this.variables = Object.filter(
                    this.$store.state.snapshot.objects,
                    object =>
                        object.parentID == instance.data.targetID &&
                        object.type == 2 &&
                        object.hidden != true
                );
                this.variables = Object.values(this.variables);
                this.variables.sort((a, b) =>
                    a.position > b.position
                        ? 1
                        : b.position > a.position
                        ? -1
                        : 0
                );
            } else {
                this.guid = instance.data.moduleID;
            }
        },
        getValue(object) {
            const rawVal = object.data.value;
            const profile = this.getProfile(object);
            if (profile && profile.associations.length >= 2) {
                return profile.associations[+rawVal].name;
            } else {
                return rawVal;
            }
        },
        getProfile(variable) {
            const item = variable.data;
            let profileName = item.profile;
            if (item.customProfile != '') {
                profileName = item.customProfile;
            }
            const profile = this.$store.state.snapshot.profiles[profileName];
            return profile;
        },
        updateTileColor(variable) {
            if (variable.ident == 'Warning') {
                this.warning = variable.data.value;
            }
        },
        getParentName(object) {
            if (!object) {
                return 'Objekt existiert nicht';
            }
            return this.$store.state.snapshot.objects['ID' + object.parentID]
                .name;
        }
    },
    mounted() {
        this.initialize(this.instance);
        const guids = {
            sammelwarnung: '{B23087BE-815D-9360-8CBD-B0DE995E0789}'
        };
        let warning = Object.filter(
            this.variables,
            object => object.ident == 'Warning'
        );
        warning = Object.values(warning);
        if (warning.length == 1) {
            this.warning = Object.values(warning)[0].data.value;
        }
        if (this.guid == guids.sammelwarnung) {
            this.isSammelwarnung = true;
            // get Warnings Variable
            let warnings = Object.filter(
                this.variables,
                object => object.ident == 'Warnings'
            );
            // get Warnings value
            warnings = JSON.parse(
                warnings[Object.keys(warnings)[0]].data.value
            );
            warnings.forEach((warning, index) => {
                const object = this.$store.state.snapshot.objects[
                    'ID' + warning.id
                ];
                warnings[index].object = object;
            });
            this.sammelwarnungen = warnings;
        }
        /*
        const profile = this.getProfile();
        let val = this.instance.data.target.data.value;
        if (profile.associations.length > 0) {
            val = profile.associations.find(assoc => assoc.value == val).name;
        }
        this.value = profile.prefix + val + profile.suffix;
        */
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: rgb(59, 54, 50);
}
.filter-warning {
    filter: invert(20%) sepia(37%) saturate(7286%) hue-rotate(346deg)
        brightness(100%) contrast(101%);
}
.filter-ok {
    filter: invert(22%) sepia(27%) saturate(1099%) hue-rotate(25deg)
        brightness(94%) contrast(91%);
}
</style>
