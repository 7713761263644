<template>
    <v-list-item>
        <v-list-item-avatar>
            <v-img
                :src="
                    $api.getIcon(
                        variable.icon ? variable.icon : 'HollowDoubleArrowRight'
                    )
                "
            ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title
                style="font-size: 15px"
                v-html="variable.name"
            ></v-list-item-title>
            <IpsProfile
                :variable="variable"
                :fontSize="fontSize"
                :showsubtitle="false"
                @execute="execute($event)"
            ></IpsProfile>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import IpsProfile from '../profiles/IPSProfile.vue';
export default {
    components: { IpsProfile },
    props: ['variable', 'fontSize'],
    methods: {
        execute(event) {
            this.$emit('execute', event);
        }
    }
};
</script>
